import React, { useState, useRef, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiAutoMode, mdiCreationOutline } from "@mdi/js";
import LoadingModal from "../../components/common/Modal/LoadingModal";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import { useModal } from "../../components/common/Modal/ModalContext";
import { useTranslation } from "../../components/common/LanguageProvider";
import MaxHeightInput from "../../components/common/MaxLineInput";
import eskritorLogo from "../../assets/img/logo-eskritor-nobuffer.png";
import editorLogo from "../../assets/img/icon/editor.svg";
import rewriteLogo from "../../assets/img/icon/refresh.svg";
import ContentLanguageDropdown from "./ContentLanguageDropdown";
import { showToast } from "../../components/common/toast";

function PromptGenerator({
  minutes,
  onWrite,
  onLanguageChange,
  initialLanguage,
}) {
  const { t } = useTranslation();
  const [generatingPrompt, setGeneratingPrompt] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [generatingSummary, setGeneratingSummary] = useState(false);
  const [summary, setSummary] = useState("");
  const navigate = useNavigate();
  const [showSummary, setShowSummary] = useState(false);
  const { getStatus, hashedId } = useAuth();
  const { showModal } = useModal();
  const lastPrompts = useRef([]);
  const [sliderValue, setSliderValue] = useState(0);
  const dropdownRef = useRef(null);
  const dropdownRefTone = useRef(null);
  var oldTitles = useRef([]);
  var oldSummaries = useRef([]);
  const [formats, setFormats] = useState([]);
  const [showDropdownFormat, setShowDropdownFormat] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(
    localStorage.getItem("originalFormat")
      ? {
          originalFormat: localStorage.getItem("originalFormat"),
          translatedFormat: localStorage.getItem("translatedFormat"),
        }
      : { originalFormat: "Paragraph", translatedFormat: "Paragraph" }
  );

  const [showDropdownTone, setShowDropdownTone] = useState(false);
  const [generateTones, setGenerateTones] = useState([]);
  const [selectedTone, setSelectedTone] = useState(
    localStorage.getItem("originalTone")
      ? {
          originalTone: localStorage.getItem("originalTone"),
          translatedTone: localStorage.getItem("translatedTone"),
        }
      : { originalTone: "Automatic", translatedTone: "Automatic" }
  );
  const selectedLanguage = useRef(
    initialLanguage ||
      localStorage.getItem("writing_language") ||
      localStorage.getItem("dashboard-language") ||
      "EN"
  );

  const lengths = ["Auto", "Short", "Medium", "Long"];
  useEffect(() => {
    getFormatsFromAPI();
    getTonesFromAPI();
    oldTitles.current = [];
    oldSummaries.current = [];
  }, []);

  function getFormatsFromAPI() {
    let lang = localStorage.getItem("dashboard-language") || "en";
    let url =
      process.env.REACT_APP_MODE === "development"
        ? `https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/eskritorcore/getgenerateformats`
        : process.env.REACT_APP_MODE === "beta"
        ? `https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/eskritorcore/getgenerateformats`
        : `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/getgenerateformats`;
    url += `?lang=${lang}&app=eskritor&platform=web`;
    fetch(url).then(function (response) {
      response.json().then(function (data) {
        setFormats(data[0]["formats"]);
        if (selectedFormat === "") {
          setSelectedFormat(data[0]["formats"][0]);
        } else {
          let newFormat = data[0]["formats"].find(
            (format) =>
              format["originalFormat"] === selectedFormat["originalFormat"]
          );
          if (newFormat) {
            setSelectedFormat(newFormat);
          }
        }
      });
    });
  }

  function getTonesFromAPI() {
    let lang = localStorage.getItem("dashboard-language") || "en";
    let url = `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/getgeneratetones?lang=${lang}&app=eskritor&platform=web`;
    fetch(url).then(function (response) {
      response.json().then(function (data) {
        setGenerateTones(data[0]["tones"]);
        if (selectedTone === "") {
          setSelectedTone(data[0]["tones"][0]);
        } else {
          let newTone = data[0]["tones"].find(
            (tone) => tone["originalTone"] === selectedTone["originalTone"]
          );
          if (newTone) {
            setSelectedTone(newTone);
          }
        }
      });
    });
  }

  const generate_prompt = async (title, summary, input_type) => {
    try {
      const argPrompts = JSON.stringify(lastPrompts.current.slice(0, 5));
      let url =
        process.env.REACT_APP_MODE === "development"
          ? "https://qkexvi4g90.execute-api.eu-central-1.amazonaws.com/eskritorcore/generateprompt?app=eskritor"
          : "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/eskritorcore/generateprompt?app=eskritor";
      url += `&hashed_id=${hashedId}`;
      url += "&platform=web";
      url += "&title=" + title;
      url += "&format=" + selectedFormat["originalFormat"];
      url += "&tone=" + selectedTone["originalTone"];
      url += "&length=" + lengths[sliderValue];
      if (input_type === "summary" && oldSummaries.current.length > 0) {
        url += "&older_prompts=" + JSON.stringify(oldSummaries.current);
      }
      if (input_type === "title" && oldTitles.current.length > 0) {
        url += "&older_prompts=" + JSON.stringify(oldTitles.current);
      }

      if (title !== "" && input_type === "title") {
        oldTitles.current.push(title);
      }
      if (summary !== null) {
        url += "&summary=" + summary;
        if (summary !== "" && input_type === "summary")
          oldSummaries.current.push(summary);
      } else {
        url += "&summary=";
      }
      url += "&input_type=" + input_type;
      url += "&language=" + selectedLanguage.current;

      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error("Failed to generate prompt: ", error);
      showToast("Unable to generate the prompt, please try again.", "error");
      setGeneratingSummary(false);
      setGeneratingPrompt(false);
    } finally {
      lastPrompts.current = [prompt, ...lastPrompts.current];
      getStatus();
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefTone.current &&
        !dropdownRefTone.current.contains(event.target)
      ) {
        setShowDropdownTone(false); // Close the dropdown if the click is outside
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdownFormat(false); // Close the dropdown if the click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedFormat(option);
    setShowDropdownFormat(false);
    localStorage.setItem("originalFormat", option["originalFormat"]);
    localStorage.setItem("translatedFormat", option["translatedFormat"]);
  };

  const handleOptionClickTone = (option) => {
    setSelectedTone(option);
    setShowDropdownTone(false);
    localStorage.setItem("originalTone", option["originalTone"]);
    localStorage.setItem("translatedTone", option["translatedTone"]);
  };
  const onContinue = async () => {
    setGeneratingSummary(true);
    setShowSummary(true);

    const res = await generate_prompt(prompt, null, "summary");
    if (res) {
      setSummary(res.prompt);
      setGeneratingSummary(false);
    }
  };

  const writeFocus = async () => {
    setGeneratingSummary(true);
    setShowSummary(true);

    const res = await generate_prompt(prompt, summary, "summary");
    if (res) {
      setSummary(res.prompt);
      setGeneratingSummary(false);
    }
  };

  const writeTitle = async () => {
    setGeneratingPrompt(true);

    const res = await generate_prompt(prompt, summary, "title");
    if (res) {
      setPrompt(res.prompt);
      setGeneratingPrompt(false);
    }
  };

  return (
    <>
     <div className="text-center">
        <h3 class="ft-18-title text-semibold  mb-1">
          {t("Focused Content Writer")}
        </h3>
      
      </div>
      <label className="ft-18-title " style={{ marginLeft: "10px" }}>
        {t("Writing Language")}
      </label>

      <ContentLanguageDropdown
        language={selectedLanguage.current}
        onLanguageChange={(lang) => {
          selectedLanguage.current = lang;
          if (onLanguageChange) {
            onLanguageChange(lang);
          }
        }}
      />
      <div class=" text-start mt-1 mb-2">
        <label style={{ marginLeft: "10px" }} className="ft-18-title">
          {t("Length")}
        </label>
        <div class="water-slider w-100 mb-1">
          <div class="range-slider">
            {/* <span class="range-slider__value">{sliderValue}</span> */}
            {/* <input id="range-slider-val" class="range-slider__range browser-default" type="range" value="2000" min="0" max="5000"/> */}
            <input
              id="range-slider-val"
              className="range-slider__range browser-default"
              type="range"
              value={sliderValue}
              min="0"
              max="3"
              onChange={handleSliderChange}
            />
          </div>
        </div>
        <div class="w-100">
          <div class="input-range-label">
            <span>{t("Auto")}</span>
            <span>{t("Short")}</span>
            <span>{t("Medium")}</span>
            <span>{t("Long")}</span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div class=" text-start mt-1 mb-2 w-100 me-2">
          <label style={{ marginLeft: "10px" }} className="ft-18-title">
            {t("Format")}
          </label>
          <div
            className={`selectBox ${showDropdownFormat ? "show" : ""}`}
            ref={dropdownRef}
          >
            <div
              className="form-control round-input border-input selectBox__value"
              onClick={() => setShowDropdownFormat(!showDropdownFormat)}
            >
              {selectedFormat["translatedFormat"]}
            </div>
            {showDropdownFormat && (
              <div className={`dropdown-menu select-small-dropdown-menu `}>
                {formats.map((option) => {
                  return (
                    <a
                      key={option["originalFormat"]}
                      href="#"
                      className={`dropdown-item ${
                        selectedFormat["translatedFormat"] ===
                        option["translatedFormat"]
                          ? "active"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOptionClick(option);
                      }}
                    >
                      {option["translatedFormat"]}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div class="text-start mt-1 mb-2 w-100">
          <label style={{ marginLeft: "10px" }} className="ft-18-title">
            {t("Tone")}
          </label>
          <div
            className={`selectBox ${showDropdownTone ? "show" : ""}`}
            ref={dropdownRefTone}
          >
            <div
              className="form-control round-input border-input selectBox__value"
              onClick={() => setShowDropdownTone(!showDropdownTone)}
            >
              {selectedTone["translatedTone"]}
            </div>
            {showDropdownTone && (
              <div className={`dropdown-menu  select-small-dropdown-menu `}>
                {generateTones.map((option) => (
                  <a
                    key={option["originalTone"]}
                    href="#"
                    className={`dropdown-item ${
                      selectedTone === option ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleOptionClickTone(option);
                    }}
                  >
                    {option["translatedTone"]}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label
          className="ft-18-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
        >
          <span>{t("Title")}</span>
          {!generatingPrompt &&
            !generatingSummary &&
            (summary !== "" || prompt !== "") && (
              <div>
                {prompt === "" ? (
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={t("Write")}
                    style={{
                      marginLeft: "1em",
                      color: "#FF981A",
                      fontWeight: "bold",
                      cursor: "pointer",
                      display: showSummary ? "" : "none",
                    }}
                    onClick={writeTitle}
                  >
                    <Icon path={mdiCreationOutline} size={1.2} />
                  </span>
                ) : (
                  <>
                    <span
                      style={{
                        marginLeft: "1em",
                        color: "#FF981A",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={writeTitle}
                    >
                      <Icon
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={t("Rewrite")}
                        path={mdiAutoMode}
                        size={1.2}
                      />
                    </span>
                    <span
                      style={{
                        marginLeft: "1em",
                        color: "red",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setPrompt("");
                      }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={t("Delete")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                      </svg>
                    </span>
                  </>
                )}
              </div>
            )}
        </label>
        {generatingPrompt ? (
          <div
            className="form-control round-input text-center"
            style={{ backgroundColor: "transparent", height: "50px" }}
          >
            <div class="loading-threedots">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        ) : (
          <MaxHeightInput
            placeholder={t("Title of the content...")}
            value={prompt}
            onChange={setPrompt}
          />
        )}
      </div>

      {showSummary ? (
        <>
          <label
            className="ft-18-title mb-2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "10px",
            }}
          >
            <span>{t("Outline")}</span>
            {!generatingSummary &&
              !generatingPrompt &&
              (summary !== "" || prompt !== "") && (
                <div>
                  {summary === "" ? (
                    <span
                      style={{
                        marginLeft: "1em",
                        color: "#FF981A",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={writeFocus}
                    >
                      <Icon
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={t("Write")}
                        path={mdiCreationOutline}
                        size={1.2}
                      />
                    </span>
                  ) : (
                    <>
                      <span
                        style={{
                          marginLeft: "1em",
                          color: "#FF981A",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={writeFocus}
                      >
                        <Icon
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title={t("Rewrite")}
                          path={mdiAutoMode}
                          size={1.2}
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: "1em",
                          color: "red",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setSummary("");
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={t("Delete")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                        </svg>
                      </span>
                    </>
                  )}
                </div>
              )}
          </label>
          {generatingSummary ? (
            <div
              className="form-control round-input text-center"
              style={{ backgroundColor: "transparent", height: "160px" }}
            >
              <div class="loading-threedots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          ) : (
            <MaxHeightInput
              placeholder={t("Focus of the content...")}
              value={summary}
              onChange={setSummary}
              argBaseHeight={90}
              argMaxHeight={180}
            />
          )}

          {summary === "" && !generatingSummary ? (
            <div class="text-center">
              <button
                class="btn theme-btn theme-border-btn min-btn-width mt-2 "
                disabled={prompt === "" || generatingPrompt}
                onClick={onContinue}
              >
                {t("Create Outline")}
              </button>
            </div>
          ) : (
            <div class="text-center">
              <button
                class="btn theme-btn  min-btn-width mt-2"
                disabled={
                  prompt === "" ||
                  generatingPrompt ||
                  summary === "" ||
                  generatingSummary
                }
                onClick={(e) => {
                  if (minutes < 1) {
                    showModal("UPGRADE-GENERATE");
                    return;
                  }
                  if (onWrite) {
                    onWrite({
                      format: selectedFormat["originalFormat"],
                      tone: selectedTone["originalTone"],
                      length: lengths[sliderValue],
                      title: prompt,
                      summary: summary,
                    });
                  } else {
                    const url = `https://editor.eskritor.com?uid=${hashedId}&lang=${localStorage.getItem(
                      "dashboard-language"
                    )}&format=${selectedFormat["originalFormat"]}&tone=${
                      selectedTone["originalTone"]
                    }&length=${
                      lengths[sliderValue]
                    }&title=${prompt}&summary=${summary}&writing_language=${
                      selectedLanguage.current
                    }`;
                    window.location.href = url;
                  }
                }}
              >
                {t("Write")}
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <div class="text-center">
            <button
              class="btn theme-btn theme-border-btn min-btn-width mt-1 "
              disabled={prompt === "" || generatingPrompt}
              onClick={onContinue}
            >
              {t("Create Outline")}
            </button>
          </div>
          {/*
					
					<div className='mt-5'>
					
						<h2 >{t("Write anything with 3 steps")}</h2>

						<ol>
							<li className='mt-3'>{t("Write your title or topic")}</li>
							<li className='mt-3'>{t("Adjust focus areas of the draft")}</li>
							<li className='mt-3'>{t("Edit your draft with AI")}</li>
						</ol>

					</div>
					<div class="text-center" >
					<img src={editorLogo} className='img-fluid' alt='img' style={{ height: '60px', marginTop:'100px' }}></img>
					</div>
					*/}
        </>
      )}
    </>
  );
}

export default PromptGenerator;

/*

<div class="text-center">
						<button class="btn theme-btn theme-border-btn min-btn-width mt-1 me-3" disabled={prompt === '' || generatingPrompt} onClick={(e) => {
							if (minutes < 1) {
								showModal("UPGRADE-GENERATE");
								return
							}
							generate_prompt()
						}}>
							<span className="me-2" style={{ backgroundColor: "transparent" }}>
								<svg viewBox="0 0 24 24" className='command-svg' width="16px" height="16px">
									<g>
										<rect fill="none" height="16px" width="16px" x="0"></rect>
									</g>
									<g>
										<g>
											<path fill="currentColor" d="M20.45,6l0.49-1.06L22,4.45c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49L20.45,2c-0.18-0.39-0.73-0.39-0.91,0l-0.49,1.06 L18,3.55c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L19.55,6C19.72,6.39,20.28,6.39,20.45,6z"></path><path fill="currentColor" d="M8.95,6l0.49-1.06l1.06-0.49c0.39-0.18,0.39-0.73,0-0.91L9.44,3.06L8.95,2C8.78,1.61,8.22,1.61,8.05,2L7.56,3.06L6.5,3.55 c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49L8.05,6C8.22,6.39,8.78,6.39,8.95,6z"></path><path fill="currentColor" d="M19.55,13.5l-0.49,1.06L18,15.05c-0.39,0.18-0.39,0.73,0,0.91l1.06,0.49l0.49,1.06c0.18,0.39,0.73,0.39,0.91,0l0.49-1.06 L22,15.95c0.39-0.18,0.39-0.73,0-0.91l-1.06-0.49l-0.49-1.06C20.28,13.11,19.72,13.11,19.55,13.5z"></path><path fill="currentColor" d="M17.71,9.12l-2.83-2.83c-0.39-0.39-1.02-0.39-1.41,0L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41l2.83,2.83 c0.39,0.39,1.02,0.39,1.41,0l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.21,11.21l-1.41-1.41l1.38-1.38l1.41,1.41 L14.21,11.21z"></path>
										</g>
									</g>
								</svg>
							</span>
							{t("Improve")}</button>
						<button class="btn theme-btn  min-btn-width mt-1" disabled={prompt === '' || generatingPrompt} onClick={(e) => {
							if (minutes < 1) {
								showModal("UPGRADE-GENERATE");
								return
							}
							if (onWrite) {
								onWrite({ prompt: prompt, format: selectedFormat['originalFormat'], tone: selectedTone['originalTone'], length: lengths[sliderValue] })
							} else {
								navigate("/editor", { state: { prompt: prompt, format: selectedFormat['originalFormat'], tone: selectedTone['originalTone'], length: lengths[sliderValue] } })
							}
						}
						}>{t("Write")}</button>
					</div>


					*/
